<template>
  <div
    @click="selectDay()"
    class="date-column text-caption pa-2"
    :this-month="isThisMonth"
  >
    <div v-if="costTotal > 0" class="blue--text mb-2 font-weight-bold">
      <div v-if="!isMobile">
        <span v-if="showDailyWarning" class="mr-1">⚠️</span>
        <span>日開銷: ${{costTotal}}</span>
      </div>

      <div v-else>
        <div v-if="showDailyWarning" class="mr-1">⚠️</div>
        <div>{{ $helper.amountChinese(costTotal) }}</div>
      </div>
    </div>

    <div v-if="incomeTotal > 0" class="green--text mb-2 font-weight-bold">
      <span v-if="!isMobile">收入: ${{incomeTotal}}</span>
      <span v-else>{{$helper.amountChinese(incomeTotal)}}</span>
    </div>

    <!-- 開銷細項 -->
    <div v-if="!isMobile">
      <div
        class="accounting-record white--text"
        v-for="(record, index) in records"
        :key="index"
        :class="getClass(record)"
      >
        <span v-if="showRecordWarning(record)" class="mr-1">⚠️</span>
        <span>{{ record.name }}  {{ record.amount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {mapGetters} from 'vuex'

export default {
  props: {
    date: {
      type: String,
      required: true,
    },
    reloadRecords: {
      type: Function,
      required: true,
    },
    yearMonth: {
      type: String,
      required: true,
    },
  },
  methods: {
    selectDay() {
      if (!this.isThisMonth) {
        return
      }
      this.$apopup.base({
        width: '350px',
        title: dayjs(this.date).format('YYYY/MM/DD'),
        bodySlot: () => import('modules/accounting/partials/calendar/datePopup/datePopup.vue'),
        date: this.date,
        applyOnEnter: false,
        persistent: true,
        record: window.eagleLodash.cloneDeep(this.records),
        reloadRecords: () => this.reloadRecords(),
        getRecords: () => window.eagleLodash.cloneDeep(this.records),
        disabledApply: (data) => {
          if (!data) {
            return true
          }
          return this.$helper.validRecords(data.update) == false
        },
        applyCallback: async (applyData) => {
          if (!applyData.isChange) {
            return
          }
          await this.updateRecordsRequest(applyData)
        },
      })
    },
    async updateRecordsRequest(applyData) {
      this.$emit('updateApplyProcessing', true)
      try {
        await this.$api.collection.accountingApi.saveRecords(
          [],
          applyData.update,
          applyData.delete,
        )
      } catch(error) {
        console.error(error)
      } finally {
        this.$emit('updateApplyProcessing', false)
        await this.reloadRecords()
        await this.$nextTick()
        this.$emit('scrollToPopupBottom')
      }
    },
    getClass(record) {
      return [this.$helper.getAccountingRecordTypeColorClass(record.record_type)]
    },
    showRecordWarning(record) {
      if (record.record_type != 'cost') {
        return false
      }
      return record.amount >= this.warningAmountSingle
    },
  },
  computed: {
    isMobile() {
      return this.$helper.isMobile()
    },
    records() {
      return this.$store.getters['accounting/recordsOfDate'](this.date)
    },
    costTotal() {
      const costRecords = this.records.filter(record => record.record_type === 'cost')
      return window.eagleLodash.sumBy(costRecords, 'amount')
    },
    showDailyWarning() {
      return this.costTotal >= this.warningAmountDaily
    },
    incomeTotal() {
      const incomeRecords = this.records.filter(record => record.record_type === 'income')
      return window.eagleLodash.sumBy(incomeRecords, 'amount')
    },
    isThisMonth() {
      const yearMonth = dayjs(this.date).format('YYYY-MM')
      return this.yearMonth === yearMonth
    },
    ...mapGetters({
      siteConfig: 'base/siteConfig',
    }),
    warningAmountSingle() {
      return this.siteConfig.warningAmountSingle
    },
    warningAmountDaily() {
      return this.siteConfig.warningAmountDaily
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
@import '~assets/sass/variable.sass'
.date-column
  height: calc(100% - 45px)
  margin-top: 1px
  overflow-y: auto
  border: 1px solid transparent
  transition: ease-in-out 0.3s
  &[this-month]
    cursor: pointer
    &:hover
      border: 1px solid $cyan
      transition: ease-in-out 0.3s

.accounting-record
  padding: 2px 4px
  margin-bottom: 4px
  border-radius: 4px
</style>
